<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="text-primary text-center">Create Revenue Share</h3>
        <hr />
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="organization"
                >Name Of Revenue Share<span class="text-danger">*</span>
                :</label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="type name of revenue share..."
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Description<span class="text-danger">*</span> :</label
              >
              <textarea
                v-model="description"
                class="form-control"
                rows="4"
                placeholder="type description about revenue share..."
              ></textarea>
            </div>

            <div class="form-group">
              <label for="organization"
                >Default Fixed Amount<span class="text-danger">*</span> :
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="converted data is the default fixed amount data that has been converted and will store to the database"
                ></i>
              </label>
              <input
                v-model="fixedAmount"
                v-on:input="setFixed"
                type="number"
                class="form-control"
                placeholder="type value of default fixed amount..."
              />
              <small class="font-weight-bold"
                >converted data : {{ convertedFixed }}</small
              >
            </div>

            <div class="form-group">
              <label for="organization"
                >Default Percentage Amount<span class="text-danger">*</span>
                :
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="converted data is the default percentage amount data that has been converted and will store to the database"
                ></i>
              </label>
              <input
                v-model="percentageAmount"
                v-on:input="setPercentage"
                type="number"
                class="form-control"
                placeholder="type value of default percentage amount..."
              />
              <small class="font-weight-bold"
                >converted data : {{ convertedPercentage }}</small
              >
            </div>

            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="is_active"
                v-model="isActive"
              />
              <label class="form-check-label" for="is_active">Is Active</label>
            </div>

            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="addRevenueShare()"
            >
              <i class="fa fa-plus"></i>
              Create Revenue Share
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'VendorAggrementCreate',
  data() {
    return {
      name: '',
      description: '',
      fixedAmount: 0,
      percentageAmount: null,
      isActive: true,
      convertedFixed: 0,
      convertedPercentage: 0,
    };
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/revenueshares');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Create Revenue Share | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.revenueshares.isLoading,
      isError: (state) => state.revenueshares.isError,
      successMessage: (state) => state.revenueshares.successMessage,
      errorMessage: (state) => state.revenueshares.errorMessage,
    }),
  },
  methods: {
    ...mapActions('revenueshares', ['createRevenueShare']),

    addRevenueShare() {
      const payload = {
        name: this.name,
        description: this.description,
        fixedAmount: this.fixedAmount,
        percentageAmount: this.percentageAmount,
        isActive: this.isActive,
      };

      this.createRevenueShare(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },

    setFixed() {
      this.convertedFixed = this.fixedAmount;
    },
    setPercentage() {
      this.convertedPercentage = this.percentageAmount
        ? (parseFloat(this.percentageAmount) / 100).toFixed(3)
        : 0;
    },
  },
};
</script>
