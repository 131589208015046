var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h3', {
    staticClass: "text-primary text-center"
  }, [_vm._v("Create Revenue Share")]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-3"
  }), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Name Of Revenue Share"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "type name of revenue share..."
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Description"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.description,
      expression: "description"
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "4",
      "placeholder": "type description about revenue share..."
    },
    domProps: {
      "value": _vm.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.description = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Default Fixed Amount"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : "), _c('i', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "fa fa-question-circle",
    attrs: {
      "title": "converted data is the default fixed amount data that has been converted and will store to the database"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fixedAmount,
      expression: "fixedAmount"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "placeholder": "type value of default fixed amount..."
    },
    domProps: {
      "value": _vm.fixedAmount
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.fixedAmount = $event.target.value;
      }, _vm.setFixed]
    }
  }), _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v("converted data : " + _vm._s(_vm.convertedFixed))])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Default Percentage Amount"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : "), _c('i', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "fa fa-question-circle",
    attrs: {
      "title": "converted data is the default percentage amount data that has been converted and will store to the database"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.percentageAmount,
      expression: "percentageAmount"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "placeholder": "type value of default percentage amount..."
    },
    domProps: {
      "value": _vm.percentageAmount
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.percentageAmount = $event.target.value;
      }, _vm.setPercentage]
    }
  }), _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v("converted data : " + _vm._s(_vm.convertedPercentage))])]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isActive,
      expression: "isActive"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "is_active"
    },
    domProps: {
      "checked": Array.isArray(_vm.isActive) ? _vm._i(_vm.isActive, null) > -1 : _vm.isActive
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.isActive,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.isActive = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isActive = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isActive = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "is_active"
    }
  }, [_vm._v("Is Active")])]), _c('br'), _c('br'), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.addRevenueShare();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Create Revenue Share ")])]), _c('div', {
    staticClass: "col-sm-3"
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }